<template>
	<div class="index">
		<MakeFormLayout></MakeFormLayout>
	</div>
</template>

<script>
export default {}
</script>

<style lang="less">
.index {
	height: 100vh;
}
</style>